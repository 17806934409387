import * as resource from "css/resource"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import _get from "lodash/get"
import React, { useState } from "react"
import { If } from "src/components"
import PageLayout from "src/components/PageLayout"
import useHubspotForm from "src/hooks/use-hubspot-form"
import "src/queries/featured_media"
import * as util from "src/util"
import DraftLabel from "../components/DraftLabel"
import SEO from "../components/seo"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import AfterDownloadModal from "../views/library/components/AfterDownloadModal"

const ArticleTemplate = ({ data }) => {
  const post = data.wordpressWpShadowhuntIntel
  const { siteUrl } = useSiteMetadata()
  let otherMetaTags = util.compileArticleSEOFields(post, siteUrl)
  const postContent = util.replaceImageBase(post.content)
  const [imageUrl, altText] = util.computeImageAttr(post)
  const [hasSubmitted, setSubmitted] = useState(false)

  const pdfLink = util.replaceImageSEOBase(
    _get(post, "acf.pdf_link.source_url", null)
  )
  const { error } = useHubspotForm({
    formId: "34154bea-73c8-4faa-9db6-d5d415ed8e38",
    target: "#download-form",
    onFormSubmitted: () => {
      util.trackEvent("ANY_FORM_SUBMISSION", "Submit")
      if (pdfLink) {
        window.location.href = pdfLink
      } else {
        util.logError("ShadowHunt Intel pdf link not found", data)
      }
      setSubmitted(true)
    },
  })
  return (
    <PageLayout options={{ light: true, headingBackground: false }}>
      <SEO
        yoastMeta={post.yoast_meta}
        title={post.title}
        ogType="article"
        extra={otherMetaTags}
        description={post.excerpt}
      />
      <div css={resource.wrapper}>
        <div css={resource.wrapperInner}>
          <div css={resource.top}>
            <div css={resource.topContent}>
              <DraftLabel status={post.status} />
              <div css={resource.category}>Shadowhunt Intel</div>
              <h1 css={resource.title}>{post.title}</h1>
              <button
                type="button"
                css={resource.button}
                onClick={() => util.smoothScrollToHash("#form")}
              >
                Get the techniques
              </button>
            </div>
            <div css={resource.topImage}>
              <If test={imageUrl}>
                <div>
                  <Img
                    fluid={imageUrl}
                    alt={altText}
                    loading="eager"
                    fadeIn={false}
                  />
                </div>
              </If>
            </div>
          </div>
        </div>
        <div css={resource.main}>
          <div css={resource.mainInner}>
            <div css={resource.mainText}>
              <div dangerouslySetInnerHTML={{ __html: postContent }} />
            </div>
            <div css={resource.form} id="form">
              <div css={resource.formWrapper}>
                <h3 css={resource.formHeading}>Grab Your Copy</h3>
                <div id="download-form" className="hs-form-wrapper">
                  {error ? "Could not load form" : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AfterDownloadModal open={hasSubmitted} link={pdfLink} />
    </PageLayout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query ShadowhuntTemplate($id: String!) {
    wordpressWpShadowhuntIntel(id: { eq: $id }) {
      title
      content
      excerpt
      slug
      date
      status
      modified
      acf {
        pdf_link {
          source_url
        }
      }
      ...FeaturedMediaShadowhuntIntel
      yoast_meta {
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_redirect
        yoast_wpseo_opengraph_title
        yoast_wpseo_opengraph_description
        yoast_wpseo_metakeywords
        yoast_wpseo_metadesc
        yoast_wpseo_meta_robots_noindex
        yoast_wpseo_meta_robots_nofollow
        yoast_wpseo_meta_robots_adv
        yoast_wpseo_linkdex
        yoast_wpseo_focuskw
        yoast_wpseo_canonical
      }
    }
  }
`
